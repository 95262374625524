<template>
  <div class="modal-content">
    <button type="button" class="close" @click="$emit('closeModal')">
      <span aria-hidden="true"><svg v-svg symbol="x" size="0 0 17 17" /></span>
    </button>
    <div class="modal-body" :class="{ large: !platform.site }">
      <div class="title-h2 pb-2 pr-4">
        {{ $t(`platform.${platform.site ? "code" : "link"}_for_a_platform`) }}:
        {{ platform.id }}
      </div>
      <div class="form-item mb-4" v-if="domains && domains.length">
        <div class="form-item__label">
          {{ $t("platforms.domain") }}
        </div>
        <div class="form-item__field">
          <custom-multiselect v-model="currentDomain" :list="domains" class="mb-4" :allowEmpty="false">
            <template v-slot:block="props">
              <div class="domain-wrapper">
                <span class="domain-wrapper__title">{{ currentDomain ? props.value.domain : "" }}</span>
              </div>
            </template>
            <template v-slot:list="props">
              <div class="domain-wrapper pl-2">
                <span class="domain-wrapper__title line_right font-weight-bold">{{ props.value.domain }}</span>
                <span class="domain-wrapper__date">{{ props.value.expires_at || $t("system") }}</span>
              </div>
            </template>
          </custom-multiselect>
        </div>
      </div>
      <CheckBoxWrapper v-if="platform.site" class="mb-4" v-model="platform.facebook_web_view_redirect" :text="$t('platform.redirect_to_chrome')" />
      <PlatformValue :platform="platform" :selectDomain="currentDomain" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "@/assets/css/_tubs.scss";
import "@/assets/css/_code.scss";
import "@/assets/css/_modal.scss";
import serviceDomain from "@/api/serviceDomain";
import PlatformValue from "@/components/PlatformValue";
import CheckBoxWrapper from "@/components/CheckBoxWrapper.vue";
import subaccsService from "@/api/subaccsService";

export default {
  name: "PlatformModal",
  components: {
    PlatformValue,
    CheckBoxWrapper,
  },
  props: {
    platform: {
      type: Object,
      default: null,
    },
    showSettingsLink: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      domains: [],
      currentDomain: null,
      allowNativeWebPush: true,
    };
  },
  computed: {
    ...mapGetters({
      featureFlags: "user/getFeatureFlags",
    }),
  },
  methods: {
    changeFacebookRedirect() {
      const payload = {
        facebook_web_view_redirect: Number(this.platform.facebook_web_view_redirect),
      };

      subaccsService.changeFacebookRedirect(this.platform.id, payload).then((res) => {
        if (res && res.status === 200) {
          this.$alert({
            type: "success",
            title: this.$t(`platforms.${this.platform.facebook_web_view_redirect ? "added" : "removed"}_redirect`),
          });
        }
      });
    },
  },
  watch: {
    platform: {
      handler() {
        this.changeFacebookRedirect();
      },
      deep: true,
    },
    currentDomain: {
      immediate: false,
      handler(val) {
        if (val && val.domain) {
          const payload = {
            id: this.platform.id,
            domain: val.domain,
          };
          serviceDomain.setCurrentDomain(payload);
        }
      },
    },
  },
  mounted() {
    const payload = {
      site: this.platform.site === 2 ? 1 : this.platform.site,
    };
    serviceDomain
      .getDomainModal(payload)
      .then((resp) => {
        if (resp && resp.status === 200) {
          this.domains = resp.data.data;
        }
      })
      .then(() => {
        serviceDomain.getCurrentDomain(this.platform.id).then((resp) => {
          if (resp) {
            if (resp.data.status === 200) {
              this.currentDomain = resp.data.data;
              return;
            }
          }
          this.currentDomain = this.domains[0];
        });
      });
  },
};
</script>
<style lang="scss" scoped>
.domain-wrapper {
  display: flex;
  flex: 1;

  &__title {
    flex: 0.2;
    color: #141414;

    &.line_right {
      border-right: 1px solid #e5e5ea;
    }
  }

  &__date {
    margin-left: 12px;
  }
}
.form-item {
  padding-bottom: 0;
}
</style>
