<template>
  <div class="tubs-content">
    <div class="code large">
      <div class="buttons-wrapper" :class="{ arbitrage: !platform.site }" v-if="isDesktop">
        <router-link v-if="platform.site && showSettingsLink" class="btn-settings _has-icon lower-link" exact :to="`/platform-settings/${platform.id}`">
          <svg v-svg symbol="settings-2" size="0 0 19 19" role="info"></svg>
        </router-link>
        <button type="button" class="btn-copy _has-icon lower-link" :class="{ 'only-button': !platform.site }" @click="copy">
          <span class="icon-copy m-0">
            <svg class="m-0" v-svg symbol="copy" size="0 0 13 15" role="info"></svg>
          </span>
        </button>
      </div>
      <code v-html="platform.site ? code : link" class="code__content"> </code>
    </div>
    <div class="mobile-buttons-wrapper" v-if="!isDesktop">
      <router-link v-if="platform.site && showSettingsLink" class="btn-settings _has-icon lower-link" exact :to="`/platform-settings/${platform.id}`">
        <svg v-svg symbol="settings-2" size="0 0 19 19" role="info"></svg>
      </router-link>
      <button type="button" class="btn-copy _has-icon lower-link" :class="{ 'only-button': !platform.site }" @click="copy">
        <span class="icon-copy m-0">
          <svg class="m-0" v-svg symbol="copy" size="0 0 13 15" role="info"></svg>
        </span>
        {{ $t("copy") }}
      </button>
    </div>
    <div class="macross" v-if="code && link">
      <div class="d-flex mt-4">
        <small class="add-macros" v-if="macroses.length">{{ $t("platforms.additional_parameters") }}:</small>
        <div class="macros-list">
          <div
            class="macros"
            :class="{
              active: platform.site ? code.includes(macros.value) : link.includes(macros.value),
            }"
            @click="toggleMacros(macros)"
            v-for="(macros, index) in macroses"
            :key="index"
          >
            {{ macros.value }}
          </div>
        </div>
      </div>
      <template v-if="!platform.site">
        <label class="mt-2">{{ $t("platforms.ready_templates") }}</label>
        <custom-multiselect v-model="templateModel" :list="templates" class="mb-3 templates">
          <template v-slot:block="props">
            <span v-if="props.value">
              {{ props.value.name }}
            </span>
            <span v-else>
              {{ $formatLabel("select_template") }}
            </span>
          </template>
          <template v-slot:list="props">
            <span>
              {{ props.value.name }}
            </span>
          </template>
        </custom-multiselect>
      </template>
    </div>
    <alert v-if="$alert.title" />
    <div class="hidden">
      <textarea readonly id="htttpsCopyLink" v-model="link" />
      <textarea readonly id="htttpsCopyCode" v-model="platformCode" />
    </div>
  </div>
</template>
<script>
import "@/assets/css/_macros.scss";
import siteSettingsService from "@/api/siteSettingsService";
import { mapGetters } from "vuex";
import ServicePanel from "@/api/servicePanel";

export default {
  name: "Code",
  props: {
    platform: {
      type: Object,
      default: null,
    },
    selectDomain: {
      type: Object,
      default: null,
    },
    showSettingsLink: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isDesktop: null,
      code: null,
      domain: "",
      templateModel: null,
      templates: [],
      macroses: [
        {
          name: "p1",
          value: "sub1",
        },
        {
          name: "p2",
          value: "sub2",
        },
        {
          name: "p3",
          value: "sub3",
        },
        {
          name: "p4",
          value: "sub4",
        },
      ],
      oldMacroses: {
        sub1: {
          enable: true,
          macros: "&sub1=sub1",
          newMacros: "&p1=sub1",
        },
        sub2: {
          enable: true,
          macros: "&sub2=sub2",
          newMacros: "&p2=sub2",
        },
        sub3: {
          enable: true,
          macros: "&sub3=sub3",
          newMacros: "&p3=sub3",
        },
        sub4: {
          enable: true,
          macros: "&sub4=sub4",
          newMacros: "&p4=sub4",
        },
      },
      oldMacrosesCode: "&sub1=sub1&sub2=sub2&sub3=sub3&sub4=sub4",
      macrosesCode: "&p1=sub1&p2=sub2&p3=sub3&p4=sub4",
      platformCode: null,
      customRedirect: false,
      urlParams: "",
      link: localStorage.getItem("templateLink") || null,
    };
  },
  watch: {
    selectDomain() {
      if (this.selectDomain.domain) {
        this.domain = this.selectDomain.domain;
      }
    },
    templateModel(val) {
      if (val) {
        const selectedTemplate = this.templates.find((el) => el.name == val.name);

        this.urlParams = selectedTemplate?.landing_url_params;

        if (!this.platform.site && val) this.macroses = [];

        this.setData();
      } else {
        this.macroses = [
          {
            name: "p1",
            value: "sub1",
          },
          {
            name: "p2",
            value: "sub2",
          },
          {
            name: "p3",
            value: "sub3",
          },
          {
            name: "p4",
            value: "sub4",
          },
        ];
        this.setData();
      }
    },
    oldMacroses: {
      deep: true,
      handler() {
        this.setOldCodeMacrosses();
        this.setData();
      },
    },
    platform: {
      deep: true,
      handler() {
        this.setData();
      },
    },
    domain() {
      this.oldMacroses = {
        sub1: {
          enable: true,
          macros: "&sub1=sub1",
          newMacros: "&p1=sub1",
        },
        sub2: {
          enable: true,
          macros: "&sub2=sub2",
          newMacros: "&p2=sub2",
        },
        sub3: {
          enable: true,
          macros: "&sub3=sub3",
          newMacros: "&p3=sub3",
        },
        sub4: {
          enable: true,
          macros: "&sub4=sub4",
          newMacros: "&p4=sub4",
        },
      };
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize");
  },
  mounted() {
    window.innerWidth > 768 ? (this.isDesktop = true) : (this.isDesktop = false);

    window.addEventListener("resize", (e) => {
      e.target.innerWidth < 768 ? (this.isDesktop = false) : (this.isDesktop = true);
    });

    if (!this.platform.site) {
      this.getUrlTemplates();
    }

    siteSettingsService.getFormats(this.platform.id).then((res) => {
      if (res && res.status === 200) {
        if (res.data.on_click) this.getOnClick(this.platform.id);
      }
    });
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      featureFlags: "user/getFeatureFlags",
    }),
  },
  methods: {
    getUrlTemplates() {
      ServicePanel.getUrlTemplates()
        .then((res) => {
          if (res && res.status === 200) {
            this.templates = res.data;
          }
        })
        .finally(() => {
          this.setData();
        });
    },
    async getOnClick(id) {
      try {
        const resp = await siteSettingsService.getOnClick(id);
        if (resp && resp.status === 200) {
          this.customRedirect = resp.data.type === "new_tab_left_with_redirect";
          this.setData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    copy() {
      if (this.platform.site) {
        this.$helper.copyCode("htttpsCopyCode");
      } else {
        this.$helper.copyCode("htttpsCopyLink");
      }
      this.$alert({
        title: this.$t("copied_succ"),
        type: "success",
      });
    },
    setOldCodeMacrosses() {
      let str = "";
      let newStr = "";
      const macroses = Object.values(this.oldMacroses);
      macroses.forEach((element) => {
        if (element.enable) {
          str = str.concat("", element.macros);
          newStr = newStr.concat("", element.newMacros);
        }
      });
      this.oldMacrosesCode = str;
      this.macrosesCode = newStr;
    },
    setData() {
      const { domain, platform, macroses, urlParams } = this;
      this.code = `${
        this.customRedirect
          ? `&lt;script><br/>
        var userCustomRedirectUrl = "PUT YOUR HTTP[S] URL HERE";</br>
        &lt;/script><br/><br/>`
          : ""
      }
        &lt;script type="text/javascript" 
        src="https://${domain}/process.js?id=${platform.id}${this.macrosesCode}" async> &lt;/script>`;

      if (this.macroses.length > 0) {
        this.link = `https://${domain}/tds?id=${platform.id}&p1=${macroses[0].value}&p2=${macroses[1].value}&p3=${macroses[2].value}&p4=${macroses[3].value}`;
        localStorage.setItem("templateLink", this.link);
      } else {
        this.link = `https://${domain}/tds?id=${platform.id}&${urlParams}`;
        localStorage.setItem("templateLink", this.link);
      }

      this.platformCode = `<script type="text/javascript" src="https://${domain}/process.js?id=${platform.id}${this.macrosesCode}" async>` + `<` + `/script>`;

      this.platformCode = this.customRedirect ? `<script>\n` + `var userCustomRedirectUrl = "PUT YOUR HTTP[S] URL HERE";\n` + `<` + `/script>\n` + this.platformCode : this.platformCode;
    },
    toggleMacros(macros) {
      let query = `&${macros.name}=${macros.value}`;
      let variable = this.platform.site ? "code" : "link";
      if (this[variable].includes(query)) {
        if (!this.platform.site) {
          this[variable] = this[variable].replace(query, "");
          return;
        }
        this.oldMacroses[macros.value].enable = false;
      } else {
        if (!this.platform.site) {
          this[variable] += query;
          return;
        }
        this.oldMacroses[macros.value].enable = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.buttons-wrapper {
  position: absolute;
  right: 0;
  top: 0px;
  background: rgba(233, 242, 242, 0.15);
  width: 160px;
  height: 64px;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 12px;
  gap: 8px;
  border-bottom-left-radius: 20px;
  backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  -ms-backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  &.arbitrage {
    width: 86px;
  }
}
.mobile-buttons-wrapper {
  display: flex;
  gap: 8px;
  margin-top: 12px;
}
.tubs-content {
  position: relative;

  &__buttons-wrapper {
    position: relative;

    .buttons-wrapper {
      display: flex;
      gap: 8px;
      position: absolute;
      right: 8px;
      top: 8px;
      @media (max-width: 768px) {
        bottom: -54px;
        right: 0;
      }
    }

    .btn,
    .btn-settings {
      position: static;
    }
  }
}

.scroll-on {
  overflow-y: auto;
}
</style>
